import React, { useEffect, useState } from "react";
import AddAddress from "./AddAddress";
import { getData } from "../../../api";
import Loader from "../../../components/Loader";

export default function CompanyDetails(props) {
  const { nextStep, onboardObject, stateList, summaryPage, getClientStep } =
    props;
  const [loading, setLoading] = useState(false);
  const [industryOptions, setIndustryOptions] = useState([]);

  const getIndustryData = async () => {
    setLoading(true);
    try {
      const res = await getData("get-gusto-industry", {});
      setLoading(false);
      setIndustryOptions(res.data);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };
  useEffect(() => {
    getIndustryData();
  }, []);
  return (
    <>
      {!loading && (
        <AddAddress
          nextStep={nextStep}
          onboardObject={onboardObject}
          stateList={stateList}
          industryType={industryOptions}
          summaryPage={summaryPage}
          getClientStep={getClientStep}
        />
      )}
      {loading && <Loader />}
    </>
  );
}
