export const reports = Array.from({ length: 7 }, (_, index) => ({
  id: index + 1,
  name: "Employee Changes",
  description: "Changes to employee records based on data",
}));

export const customReports = Array.from({ length: 7 }, (_, index) => ({
  id: index + 1,
  reportName: "Employee Custom Changes",
  description: "Changes to employee custom records based on data",
}));

export const reportSections = [
  {
    title: "Payroll columns",
    subtitle: "Select which data fields to include in your report",
    checkboxes: [
      { id: 1, label: "Check data" },
      { id: 2, label: "Payroll type" },
      { id: 3, label: "Pay period start date" },
      { id: 4, label: "Pay period end date" },
    ],
  },
  {
    title: "Employee columns",
    subtitle: "Select which data fields to include in your report",
    checkboxes: [
      { id: 6, label: "Employee email" },
      { id: 7, label: "Employee first name" },
      { id: 8, label: "Employee preferred first name" },
      { id: 9, label: "Employee date of birth" },
      { id: 10, label: "Employee last name" },
      { id: 11, label: "Employee preferred last name" },
      { id: 12, label: "Employee home address" },
      { id: 13, label: "Employee home phone number" },
      { id: 14, label: "Employee work phone number" },
      { id: 15, label: "Termination date" },
      { id: 16, label: "Rehire date" },
      { id: 17, label: "Payment method" },
      { id: 18, label: "Back account" },
      { id: 19, label: "Back account number" },
      { id: 20, label: "Back routing number" },
      { id: 21, label: "Employee mobile number" },
      { id: 22, label: "Employee work start date" },
      { id: 23, label: "Employee end date" },
      { id: 24, label: "Job title" },
      { id: 25, label: "Primary job title" },
      { id: 26, label: "Additional job title" },
      { id: 27, label: "Current department" },
      { id: 28, label: "Current division" },
      { id: 29, label: "Hire date" },
      { id: 30, label: "Sick time available" },
      { id: 31, label: "Sick time taken" },
      { id: 32, label: "PTO available" },
      { id: 33, label: "PTO taken" },
      { id: 34, label: "Total hours" },
      { id: 35, label: "Rate of pay" },
      { id: 36, label: "Regular earnings" },
      { id: 37, label: "Overtime earnings" },
      { id: 38, label: "Miscellaneous earnings" },
      { id: 39, label: "Overtime hours" },
      { id: 40, label: "Miscellaneous hours" },
      { id: 41, label: "Holiday hours" },
      { id: 42, label: "Hours worked" },
      { id: 43, label: "Statutory deductions" },
      { id: 44, label: "Net pay" },
    ],
  },
  {
    title: "Employer columns",
    subtitle: "Select which data fields to include in your report",
    checkboxes: [
      { id: 45, label: "Work address (line1)" },
      { id: 46, label: "Work address (street)" },
      { id: 47, label: "Work address (line2)" },
    ],
  },
  {
    title: "Employer payroll columns",
    subtitle: "Select which data fields to include in your report",
    checkboxes: [
      { id: 48, label: "Employer social security tax" },
      { id: 49, label: "Employer cost" },
      { id: 50, label: "Employer FUTA tax" },
      { id: 51, label: "Employer SUTA tax" },
      { id: 52, label: "Employer state tax" },
      { id: 53, label: "Employer federal tax" },
      { id: 54, label: "Employer Medicare tax" },
      { id: 55, label: "Employer additional taxes" },
    ],
  },
  {
    title: "Employer contributions",
    subtitle: "Select which data fields to include in your report",
    checkboxes: [
      { id: 56, label: "Employer social security tax" },
      { id: 57, label: "Employer cost" },
      { id: 58, label: "Employer FUTA tax" },
      { id: 59, label: "Employer SUTA tax" },
      { id: 60, label: "Employer state tax" },
      { id: 61, label: "Employer federal tax" },
      { id: 62, label: "Employer Medicare tax" },
      { id: 63, label: "Employer additional taxes" },
    ],
  },
];

export const SelectTagArray = Array.from({ length: 5 }, (_, i) => {
  const label = String.fromCharCode(97 + i);
  return { label, value: label, selected: false };
});
export const SelectClientTagArray = Array.from({ length: 5 }, (_, i) => {
  const label = String.fromCharCode(65 + i);
  return { label, value: label, selected: false };
});

export const EmployeeReportsData = [
  {
    employeeId: 45334,
    ssn: "499-75-4343",
    firstName: "Non-Jared",
    middleInitial: "-",
    lastName: "Miller",
    jobCode: "Palmer",
    jobLocation: "Soldotna",
    address1: "342 Ridgewood Dr, Soldotna, Alaska 99669, USA",
    city: "Soldotna",
    state: "AK",
  },
  {
    employeeId: 45335,
    ssn: "499-75-4343",
    firstName: "Jared",
    middleInitial: "-",
    lastName: "Miller",
    jobCode: "Palmer",
    jobLocation: "Soldotna",
    address1: "342 Ridgewood Dr, Soldotna, Alaska 99669, USA",
    city: "Soldotna",
    state: "AK",
  },
  {
    employeeId: 45336,
    ssn: "499-75-4342",
    firstName: "Un-Jared",
    middleInitial: "-",
    lastName: "Miller",
    jobCode: "Palmer",
    jobLocation: "Soldotna",
    address1: "342 Ridgewood Dr, Soldotna, Alaska 99669, USA",
    city: "Soldotna",
    state: "AK",
  },
  {
    employeeId: 45337,
    ssn: "499-75-4342",
    firstName: "With-Jared",
    middleInitial: "-",
    lastName: "Miller",
    jobCode: "Palmer",
    jobLocation: "Soldotna",
    address1: "342 Ridgewood Dr, Soldotna, Alaska 99669, USA",
    city: "Soldotna",
    state: "AK",
  },
];
export const onboardingSteps = [
  {
    title: "Company information",
    description:
      "Ensure smooth integration, accurate records, and streamlined operations by filling in your company details.",
  },
  {
    title: "Bank details & Payroll",
    description:
      "Ensure timely payments by adding your bank account and payroll details.",
  },
  {
    title: "Federal tax",
    description:
      "Ensure correct withholding by sharing your company's federal tax ID information.",
  },
  {
    title: "Bank verification",
    description:
      "Enable secure and hassle-free transactions by verifying your bank details.",
  },
  {
    title: "All signatory",
    description:
      "Ensure smooth operations by adding information for all authorized signatories.",
  },
];
