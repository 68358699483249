import React from "react";
import { Row, Col } from "react-bootstrap";
import HeaderForm from "../../components/HeaderForm";

import OnboardHeading from "../../components/OnboardHeading";
import AccordionComp from "./accordion";

function ClientGustoSummary(props) {
  const {
    step,
    nextStep,
    clientObject,
    stateList,
    industryType,
    payFrequencyList,
    firstPayList,
    secondPayList,
    taxPayerType,
    taxFillingForm,
    titleList,
  } = props;
  return (
    <>
      <HeaderForm />
      <div className="boarding-layout board-main-section onboarding-summary-wrapper">
        <Row>
          <Col>
            <OnboardHeading
              title="onboarding checklist"
              subtitle="Your company onboarding process is estimated to take 5 minutes."
              upperCaseTitle
            />
          </Col>
        </Row>
        <div className="onboarding-accordion-wrapper">
          <h5 className="onboarding-title-wrapper">View summary</h5>
          <AccordionComp
            step={step}
            nextStep={nextStep}
            clientObject={clientObject}
            stateList={stateList}
            industryType={industryType}
            payFrequencyList={payFrequencyList}
            firstPayList={firstPayList}
            secondPayList={secondPayList}
            taxPayerType={taxPayerType}
            taxFillingForm={taxFillingForm}
            titleList={titleList}
          />
        </div>
      </div>
    </>
  );
}

export default ClientGustoSummary;
