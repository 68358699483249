import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "../../../components/Form/Button";
import {
  mustNumbers,
  onlyAlphaNumericWithAmpersandAndHyphenAndSpace,
  fotmatNumber,
  emptySpace,
  autocloseTiming,
} from "../../../api/regex";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import { FiHelpCircle } from "react-icons/fi";
import { postData } from "../../../api";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";

const FederalTaxSetup = ({
  nextStep,
  onboardObject,
  taxPayerType,
  taxFillingForm,
  summaryPage,
  getClientStep,
}) => {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientObject, setClientObject] = useState({
    ein: "",
    tax_payer_type: "",
    filing_form: "",
    legal_name: "",
  });
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (onboardObject?.federal_tax) {
      setClientObject(onboardObject?.federal_tax);
    }
  }, [onboardObject]);
  const handleFormatChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "ein") {
      let ein_val = value?.replace(fotmatNumber, "");
      let employeeId = ein_val.slice(0, 2) + "-" + ein_val.slice(2, 9);
      if (value.length === 9) {
        setClientObject((prev) => ({
          ...prev,
          ein: employeeId,
        }));
      }
    }
  };

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setClientObject((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (!!errors[name])
      setErrors({
        ...errors,
        [name]: null,
      });
  };

  const renderTooltip = (
    <div>
      To learn more about the different federal tax form filing for payroll,
      please review the
      <a href="https://www.irs.gov/" target="_blank" without rel="noreferrer">
        {" "}
        IRS website.
      </a>
    </div>
  );

  const findFormErrors = () => {
    const { ein, tax_payer_type, legal_name, filing_form } = clientObject;
    const newErrors = {};

    var newEin = ein?.replace("-", "");
    if (!ein || ein === "")
      newErrors.ein = "Please enter your federal employer ID number.";
    else if (!ein.toString()?.match(mustNumbers)) {
      newErrors.ein = "The federal employer ID should be numeric.";
    } else if (ein.length !== 10) {
      newErrors.ein = "The federal employer ID should be 9 characters";
    } else if (newEin.length !== 9) {
      newErrors.ein = "The federal employer ID should be 9 characters";
    } else if (ein.split("-").length > 2) {
      newErrors.ein = "Invalid federal employer ID number";
    }

    if (!tax_payer_type || tax_payer_type === "")
      newErrors.tax_payer_type = "Please enter your tax type.";
    if (!filing_form || filing_form === "")
      newErrors.filing_form = "Please enter your federal filing form.";
    if (!legal_name || legal_name === "")
      newErrors.legal_name = "Please enter your entity name.";
    else if (legal_name.length < 4) {
      newErrors.legal_name =
        "The legal entity name should be  4 to 60 characters long";
    } else if (legal_name.length > 60) {
      newErrors.legal_name =
        "The legal entity name should be  4 to 60 characters long";
    } else if (
      !legal_name?.match(onlyAlphaNumericWithAmpersandAndHyphenAndSpace)
    ) {
      newErrors.legal_name =
        "The legal entity name consists of alphanumeric characters, hyphen (-), and ampersand (&).";
    } else if (!legal_name?.match(emptySpace)) {
      newErrors.legal_name =
        "The legal entity name consists of alphanumeric characters, hyphen (-), and ampersand (&).";
    }
    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = findFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      federalTaxSetup();
    }
  };

  const federalTaxSetup = async () => {
    setLoading(true);
    let data = {
      ...clientObject,
    };
    setLoading(true);
    const res = await postData("add-gusto-federal-tax", {}, data);
    if (res.status === true) {
      setLoading(false);
      nextStep(res.next_steps);
      getClientStep();
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  return (
    <>
      {!loading && (
        <div className="board-right board-tab-sections">
          <h5 className="mb-3"> Federal tax info</h5>

          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            autoComplete="off"
            className="custom-form"
          >
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="ein">
                  <Form.Label>
                    Federal Employer ID number{" "}
                    <span className="asterisk">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter federal employer ID number"
                    value={clientObject?.ein}
                    onChange={handleInput}
                    isInvalid={!!errors.ein}
                    onBlur={handleFormatChange}
                    name="ein"
                    required
                    disabled={summaryPage}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.ein}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="tax_payer_type">
                  <Form.Label>
                    Tax payer type <span className="asterisk">*</span>{" "}
                  </Form.Label>
                  <Form.Select
                    onChange={handleInput}
                    name="tax_payer_type"
                    value={clientObject.tax_payer_type}
                    required
                    size="lg"
                    disabled={summaryPage}
                  >
                    <option value="">Select type</option>
                    {taxPayerType &&
                      taxPayerType.map((item, index) => (
                        <option key={item.id} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.tax_payer_type}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="filing_form">
                  <Form.Label className="me-2">
                    Federal filing form <span className="asterisk">*</span>{" "}
                  </Form.Label>
                  <Tooltip
                    placement="right"
                    overlay={renderTooltip}
                    overlayClassName="tooltip-content"
                  >
                    <FiHelpCircle className="help-circle" />
                  </Tooltip>

                  <Form.Select
                    onChange={handleInput}
                    name="filing_form"
                    value={clientObject.filing_form}
                    required
                    size="lg"
                    disabled={summaryPage}
                  >
                    <option value="">Select form</option>
                    {taxFillingForm &&
                      taxFillingForm.map((item, index) => (
                        <option key={item.id} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.filing_form}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="legal_name">
                  <Form.Label>
                    Legal entity name <span className="asterisk">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter legal entity name"
                    value={clientObject.legal_name}
                    onChange={handleInput}
                    isInvalid={!!errors.legal_name}
                    name="legal_name"
                    required
                    disabled={summaryPage}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.legal_name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            {!summaryPage && (
              <div className="text-end mt-4">
                <Button
                  type="button"
                  className="btn-outline-primary me-2"
                  onClick={() => nextStep("bank_info")}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  disabled={loading}
                  loading={loading}
                  className="btn-primary text-white"
                >
                  Save & Continue
                </Button>
              </div>
            )}
          </Form>
        </div>
      )}
      {loading && <Loader />}
    </>
  );
};

export default FederalTaxSetup;
