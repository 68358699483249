import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import Loader from "../../../components/Loader";

const SelectIndustry = (props) => {
  const {
    onboardObject,
    industryType,
    clientObject,
    setClientObject,
    errors,
    validated,
    loading,
    summaryPage,
  } = props;

  const [industryTypeValue, setIndustryTypeValue] = useState();

  useEffect(() => {
    if (onboardObject?.industry) {
      setClientObject(onboardObject?.industry);
      let data = {
        label: onboardObject?.industry?.title,
        naics_code: onboardObject?.industry?.naics_code,
        sic_codes: onboardObject?.industry?.sic_codes,
        value: onboardObject?.industry?.naics_code,
      };
      setIndustryTypeValue(data);
      setClientObject((prev) => ({
        ...prev,
        industryType: onboardObject?.industry?.naics_code,
      }));
    }
  }, [onboardObject]);

  const formatSelectOptions = (data) => {
    let finalArr = [];
    if (data && data.length > 0) {
      data.forEach((item) => {
        finalArr.push({
          value: item?.naics_code,
          label: item?.title,
          naics_code: item?.naics_code,
          sic_codes: item?.sic_codes,
        });
      });
    }
    return finalArr;
  };

  const handleStateOnChange = async (data) => {
    setIndustryTypeValue(data);
    setClientObject((prev) => ({
      ...prev,
      industryType: data.value,
      title: data.label,
      naics_code: data.naics_code,
      sic_codes: data.sic_codes,
    }));
  };

  return (
    <>
      {!loading && (
        <div className="board-right board-tab-sections">
          <h5 className="mb-3"> Company details</h5>

          <Form
            noValidate
            validated={validated}
            // onSubmit={handleSubmit}
            autoComplete="off"
            className="custom-form"
          >
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="industryType">
                  <Form.Label>
                    Industry type <span className="asterisk">*</span>{" "}
                  </Form.Label>
                  <Select
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={formatSelectOptions(industryType)}
                    onChange={(selectedOption) =>
                      handleStateOnChange(selectedOption)
                    }
                    value={industryTypeValue}
                    placeholder="Select industry type"
                    className="custom-select"
                    isDisabled={summaryPage}
                  />
                  {validated && clientObject?.industryType === "" && (
                    <div className="err-feedback">{errors.industryType}</div>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
      )}
      {loading && <Loader />}
    </>
  );
};

export default SelectIndustry;
