import React from "react";
import AddBankInfo from "./AddBankInfo";

export default function BankDetailsPayroll(props) {
  const {
    nextStep,
    onboardObject,
    payFrequencyList,
    firstPayList,
    secondPayList,
    summaryPage,
    getClientStep,
  } = props;
  return (
    <>
      <AddBankInfo
        nextStep={nextStep}
        onboardObject={onboardObject}
        payFrequencyList={payFrequencyList}
        firstPayList={firstPayList}
        secondPayList={secondPayList}
        summaryPage={summaryPage}
        getClientStep={getClientStep}
      />
    </>
  );
}
