import React, { useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import Button from "../../../components/Form/Button";
import { postData } from "../../../api";
import { toast } from "react-toastify";
import { autocloseTiming } from "../../../api/regex";

export default function BankVerification({
  summaryPage,
  nextStep,
  onboardObject,
  getClientStep,
}) {
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({});
  const [clientObject, setClientObject] = useState({
    bank_deposit_1: "",
    bank_deposit_2: "",
  });
  const validateDeposit = (amount) => {
    if (!amount || isNaN(amount)) {
      return "Enter a valid amount.";
    } else if (parseFloat(amount) < 0.02 || parseFloat(amount) > 2.0) {
      return "Amount must be between 0.02 and 2.00.";
    } else if (!/^\d+(\.\d{1,2})?$/.test(amount)) {
      return "Amount can have up to 2 decimal places.";
    }
    return null;
  };
  useEffect(() => {
    if (onboardObject?.bank_verification) {
      setClientObject({
        bank_deposit_1: onboardObject.bank_verification.bank_deposit_1,
        bank_deposit_2: onboardObject.bank_verification.bank_deposit_2,
      });
    }
  }, [onboardObject]);

  const findFormErrors = () => {
    const { bank_deposit_1, bank_deposit_2 } = clientObject;
    const newErrors = {};
    const error1 = validateDeposit(bank_deposit_1);
    if (error1) newErrors.bank_deposit_1 = error1;
    const error2 = validateDeposit(bank_deposit_2);
    if (error2) newErrors.bank_deposit_2 = error2;
    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = findFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      handleBankVerification();
    }
  };

  const handleBankVerification = async () => {
    setLoading(true);
    let data = { ...clientObject };
    try {
      const res = await postData("add-gusto-bank-verification", {}, data);
      setLoading(false);
      setErrors({});
      nextStep(res.data.status);
      toast.success(res.message, {
        theme: "colored",
        autoClose: 5000,
      });
      getClientStep();
    } catch (e) {
      setLoading(false);
      toast.error(e.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
    }
  };

  const handleInputChange = (field, value) => {
    setClientObject((prev) => ({ ...prev, [field]: value }));
  };
  return (
    <div>
      <div className="bank-verification-title-wrapper">
        <h5>Bank Verification</h5>
        <p>
          To verify your account, enter two micro deposits below. If you don’t
          see them, they should arrive in 1-3 business days.
        </p>
      </div>
      <div className="bank-verification-input-wrapper">
        <div className="bank-verification-form-wrapper">
          <h5>
            Amount 1<span>*</span>
          </h5>
          <p>
            Enter the first deposit amount in dollars, e.g., 0.02 for 2 cents
          </p>
          <InputGroup className="amount-input-group">
            <InputGroup.Text>$</InputGroup.Text>
            <FormControl
              value={clientObject.bank_deposit_1}
              onChange={(e) =>
                handleInputChange("bank_deposit_1", e.target.value)
              }
              isInvalid={!!errors.bank_deposit_1}
              disabled={summaryPage}
            />
            {validated && (
              <div className="err-feedback">{errors.bank_deposit_1}</div>
            )}
          </InputGroup>
        </div>
        <div className="bank-verification-form-wrapper">
          <h5>
            Amount 2<span>*</span>
          </h5>
          <p>
            Enter the second deposit amount in dollars, e.g., 0.02 for 2 cents
          </p>
          <InputGroup className="amount-input-group">
            <InputGroup.Text>$</InputGroup.Text>
            <FormControl
              value={clientObject.bank_deposit_2}
              onChange={(e) =>
                handleInputChange("bank_deposit_2", e.target.value)
              }
              isInvalid={!!errors.bank_deposit_2}
              disabled={summaryPage}
            />
            {validated && (
              <div className="err-feedback">{errors.bank_deposit_2}</div>
            )}
          </InputGroup>
        </div>
      </div>
      {!summaryPage && (
        <div className="text-end mt-4">
          <Button
            type="button"
            className="btn-outline-primary me-2"
            onClick={() => nextStep("federal_tax")}
          >
            Back
          </Button>
          <Button
            type="submit"
            disabled={loading}
            loading={loading}
            onClick={handleSubmit}
            className="btn-primary text-white"
          >
            Save & Continue
          </Button>
        </div>
      )}
    </div>
  );
}
