import React from "react";
import { Form, Row, Col } from "react-bootstrap";

function NoSignatory(props) {
  const {
    validated,
    noErrors,
    handleNoInput,
    clientNoObject,
    titleList,
    summaryPage,
  } = props;
  return (
    <Form
      noValidate
      validated={validated}
      autoComplete="off"
      className="custom-form mb-4"
    >
      <Row>
        <Col md={4}>
          <Form.Group className="mb-3" controlId="first_name">
            <Form.Label>
              Name <span className="asterisk">*</span>{" "}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              name="first_name"
              maxLength="70"
              required
              value={clientNoObject.first_name}
              onChange={handleNoInput}
              disabled={summaryPage}
            />
            <Form.Control.Feedback type="invalid">
              {noErrors.name}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>{" "}
        <Col md={4}>
          <Form.Group className="mb-3" controlId="username">
            <Form.Label>
              User name <span className="asterisk">*</span>{" "}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter user name"
              name="username"
              maxLength="255"
              required
              value={clientNoObject.username}
              onChange={handleNoInput}
              disabled={summaryPage}
            />
            <Form.Control.Feedback type="invalid">
              {noErrors.username}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group className="mb-3" controlId="primary_email">
            <Form.Label>
              Email <span className="asterisk">*</span>{" "}
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              isInvalid={!!noErrors.primary_email}
              name="primary_email"
              value={clientNoObject.primary_email}
              required
              onChange={handleNoInput}
              disabled={summaryPage}
            />
            <Form.Control.Feedback type="invalid">
              {noErrors.email}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="mb-3" controlId="title">
            <Form.Label>
              Title <span className="asterisk">*</span>{" "}
            </Form.Label>
            <Form.Select
              onChange={handleNoInput}
              name="title"
              value={clientNoObject.title}
              required
              size="lg"
              disabled={summaryPage}
            >
              <option value="">Select title</option>
              {titleList &&
                titleList.map((item, index) => (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {noErrors.title}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
}

export default NoSignatory;
