import React, { useEffect } from "react";
import "./progressBar.scss";
import CheckCircle from "../../../assets/images/onboarding_icons/check_circle.svg";

const ProgressBar = (props) => {
  const { step, stepArrays, completedSteps, setStepNo } = props;
  const stepLabelMap = {
    add_address: "Company Details",
    bank_info: "Bank details & Payroll",
    federal_tax: "Federal tax",
    bank_verification: "Bank verification",
    add_signatory: "Signatory",
  };

  useEffect(() => {
    const stepIndex = stepArrays.findIndex((item) => item === step);
    if (stepIndex !== -1) {
      setStepNo(stepIndex + 1);
    }
  }, [step, stepArrays, setStepNo]);

  const getDisplayLabel = (key) => stepLabelMap[key] || key;
  return (
    <div className="progress-bar-container">
      <div className="progress-bar">
        {stepArrays.map((stepLabel, index) => (
          <div key={index} className="progress-step">
            <div className="icon-label-container">
              <div
                className={`step-icon ${
                  completedSteps.includes(stepLabel) || step === stepLabel
                    ? "step-completed"
                    : "step-pending"
                }`}
              >
                {completedSteps.includes(stepLabel) ? (
                  <img src={CheckCircle} alt="Completed" />
                ) : (
                  <span>{index + 1}</span>
                )}
              </div>
              <span
                className={
                  completedSteps.includes(stepLabel) || step === stepLabel
                    ? "step-completed step-completed-label"
                    : "step-label"
                }
              >
                {getDisplayLabel(stepLabel)}
              </span>
            </div>
            {index < stepArrays.length - 1 && (
              <div className="arrow-container">
                <svg
                  fill="none"
                  viewBox="0 0 22 80"
                  preserveAspectRatio="none"
                  className="right-arrow"
                >
                  <path
                    d="M0 -2L20 40L0 82"
                    stroke="currentColor"
                    vectorEffect="non-scaling-stroke"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
